<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="text-h5">
        Error: {{ this.error_status }}
      </v-card-title>
      <v-card-text>
        {{ this.error_message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="clearError"> Okay </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  props: {
    error: Object, // error object
  },
  computed: {
    error_status() {
      // if error object is not null, return error status, else return null
      return this.error ? this.error.status : null;
    },
    error_message() {
      // if error object is not null, return error message, else return null
      return this.error ? this.error.error : null;
    },
    dialog() {
      return this.error !== null;
    },
  },
  methods: {
    clearError() {
      this.$emit("clear_error");
    },
  },
};
</script>
