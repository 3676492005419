<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <SubmitCard />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <SubmitInfoCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubmitCard from "../components/submit/SubmitCard";
import SubmitInfoCard from "../components/submit/SubmitInfoCard";

export default {
  components: {
    SubmitCard,
    SubmitInfoCard,
  },
  name: "SubmitView",
};
</script>
