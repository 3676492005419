<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="headline">
              Conserved Unique Peptide Patterns (CUPP)
            </span>
          </v-card-title>
          <v-card-text>
            <p>
              Conserved Unique Peptide Patterns (CUPP) is a new approach for
              sequence analysis employing conserved peptide patterns for
              determination of similarities between proteins. CUPP performs
              unsupervised clustering of proteins for formation of protein
              groups and can be used for fast annotation of enzyme protein
              family, subfamily and EC function of carbohydrate-active enzymes.
            </p>
            <p>
              <strong>CUPP references:</strong>
            </p>
            <p>
              Barrett, K., Hunt, C. J., Lange, L., & Meyer, A. S. (2020).
              Conserved unique peptide patterns (CUPP) online platform:
              peptide-based functional annotation of carbohydrate active
              enzymes.
              <i>Nucleic Acids Research</i>, 48(W1), W110–W115.
              <a href="https://doi.org/10.1093/nar/gkaa375"
                >https://doi.org/10.1093/nar/gkaa375</a
              >
            </p>
            <p>
              Barrett, K., & Lange, L. (2019). Peptide-based functional
              annotation of carbohydrate-active enzymes by conserved unique
              peptide patterns (CUPP).
              <i>Biotechnology for Biofuels</i>, 12(1).
              <a href="https://doi.org/10.1186/s13068-019-1436-5"
                >https://doi.org/10.1186/s13068-019-1436-5</a
              >
            </p>
            <p>If you have any questions, please contact cupp@bio.dtu.dk</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="headline"> Acknowledgements </span>
          </v-card-title>
          <v-card-text>
            <p>
              This site and the CUPP annotation software, was developed using
              <a href="https://vuejs.org/">Vue.js</a>,
              <a href="https://www.rust-lang.org/">Rust</a>
              and, <a href="https://go.dev/">Golang</a>; hosted on
              <a href="https://cloud.google.com/">Google CLoud Platform</a>.
            </p>
            <p>
              The site makes use of
              <a href="https://nglviewer.org/ngl/api/">NGL Viewer</a>
              for visualization of protein structures,
              <a href="https://github.com/weng-lab/logojs-package/">LogoJS</a>
              for the CUPP logos,
              <a href="https://github.com/lipme/vue-svg-msa/">vue-svg-msa</a>
              for rendering of the multiple sequence alignments, and
              <a href="https://www.drive5.com/muscle/">MUSCLE</a>
              for producing the multiple sequence alignments.
            </p>
            <p>
              <strong>Other references:</strong>
            </p>
            <p>
              Rose, A. S., Bradley, A. R., Valasatava, Y., Duarte, J. M., Prlić,
              A., & Rose, P. W. (2016). Web-based molecular graphics for large
              complexes. In Proceedings of the 21st International Conference on
              Web3D Technology. Web3D ’16: The 21st International Conference on
              Web3D Technology. ACM.
              <a href="https://doi:10.1145/2945292.2945324"
                >https://doi:10.1145/2945292.2945324</a
              >
            </p>
            <p>
              Rose, A. S., & Hildebrand, P. W. (2015). NGL Viewer: a web
              application for molecular visualization. In Nucleic Acids Research
              (Vol. 43, Issue W1, pp. W576–W579). Oxford University Press (OUP).
              <a href="https://doi:10.1093/nar/gkv402"
                >https://doi:10.1093/nar/gkv402</a
              >
            </p>
            <p>
              Edgar, R. C. (2004). MUSCLE: multiple sequence alignment with high
              accuracy and high throughput. In Nucleic Acids Research (Vol. 32,
              Issue 5, pp. 1792–1797). Oxford University Press (OUP).
              <a href="https://doi.org/10.1093/nar/gkh340"
                >https://doi.org/10.1093/nar/gkh340</a
              >
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutView",
};
</script>

<style></style>
