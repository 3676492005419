<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="headline">
              Download files for CUPP-Predict (Ru-CUPP)</span
            >
          </v-card-title>
          <v-card-subtitle>
            <a
              href="https://drive.google.com/drive/folders/1b1o94AWAQTwMvLVdH1otSC68JTTXQmmU?usp=sharing"
              >CUPP-Predict</a
            >
          </v-card-subtitle>
          <v-card-subtitle>
            The older python implementation of CUP, for both clustering and
            prediction can be found
            <a
              href="https://files.dtu.dk/u/hLin6ni4p-SWuKfp/CUPP_program?l"
              target="_blank"
              >here</a
            >.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DownloadsView",
};
</script>

<style></style>
