<template>
  <v-app-bar app flat color="rgba(153, 0, 0, 1)" dark>
    <v-app-bar-nav-icon @click="toggleNavigationBar"></v-app-bar-nav-icon>
    <v-toolbar-title>Conserved Unique Peptide Patterns (CUPP)</v-toolbar-title>
  </v-app-bar>
</template>
<script>
export default {
  name: "AppBar",
  methods: {
    toggleNavigationBar() {
      this.$emit("toggleNavigationBar");
    },
  },
};
</script>
