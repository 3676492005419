<template>
  <v-navigation-drawer v-model="isVisible" fixed app>
    <v-toolbar flat dark color="rgba(153, 0, 0, 1)" class="toolbar">
      <img src="@/assets/cupp_logo.png" height="36px" alt="cupp_logo" />
    </v-toolbar>
    <v-list>
      <v-list-item v-for="([icon, text, path], i) in items" :key="i" :to="path">
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-toolbar
      width="100%"
      flat
      dark
      color="rgba(153, 0, 0, 1)"
      bottom
      absolute
      class="toolbar"
    >
      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} —
        <strong>CUPP.info (2.1.0)</strong>
      </v-card-text>
    </v-toolbar>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    toggle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  name: "NavDrawer",
  data() {
    return {
      isVisible: this.toggle,
      items: [
        ["mdi-home", "Home", "/"],
        ["mdi-file-upload", "Submit", "/submit"],
        ["mdi-magnify", "Query", "/query"],
        ["mdi-view-dashboard", "Browse CAZy", "/browse/cazy"],
        ["mdi-coffee", "Browse CUPP", "/browse/cupp"],
        ["mdi-ballot", "Browse EC", "/browse/ec"],
        ["mdi-family-tree", "Browse Taxonomy", "/browse/taxonomy"],
        ["mdi-view-list-outline", "Browse Strains", "/browse/strain"],
        ["mdi-compost", "Browse Genomes", "/browse/genome"],
        ["mdi-download", "Downloads", "/downloads"],
        ["mdi-help", "Help", "/help"],
        ["mdi-information", "About", "/about"],
      ],
    };
  },
  methods: {
    drawerIsClosed() {
      this.$emit("drawerIsClosed");
    },
  },
  watch: {
    toggle: function (newToggle) {
      this.isVisible = newToggle;
    },
    isVisible: function (newVal) {
      if (newVal === false) {
        this.drawerIsClosed();
      }
    },
  },
};
</script>
