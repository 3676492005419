<template>
  <div v-cloak @drop.prevent="addDropFile" @dragover.prevent="dragOver('over')">
    <v-card>
      <v-card-title>
        <span class="headline">Submit data for CUPP Annotation</span>
      </v-card-title>
      <v-card-subtitle>
        Annotation of carbohydrate active enzymes.
      </v-card-subtitle>
      <v-col>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
                v-model.trim="cur_job.job_name"
                label="Job Name"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                v-model.trim="cur_job.email"
                :rules="emailRules"
                label="E-mail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="cur_job.email_when_notified"
            label="Email me when job is finished?"
          ></v-checkbox>
          <p>
            <strong>Choose Either A or B, see below:</strong>
          </p>
          <label> <strong>A.</strong> Protein, gene or RNA sequences </label>
          <v-textarea
            :loading="submission_blob_loading"
            v-model.trim="submission_blob"
            outlined
          ></v-textarea>
          <label>
            <strong>B.</strong> FASTA file containing genomic DNA, RNA, protein
            or gene sequences.
          </label>
          <v-file-input
            :background-color="bck_color"
            v-model="submission_file"
            :rules="fileRules"
            ref="fileInput"
            label="FASTA file"
          ></v-file-input>
          <p>
            <strong>Choose sequence type:</strong>
          </p>
          <p>
            File size limit is 32 MB (NB: the ORF finder is intended for
            prokaryotes)
          </p>
          <v-radio-group
            @change="typeChange"
            v-model="cur_job.job_type"
            mandatory
          >
            <v-radio label="Amino acids" value="aa"></v-radio>
            <v-radio label="Nucleotides" value="nt"></v-radio>
          </v-radio-group>
          <p>
            <strong v-show="cur_job.job_type === 'nt'"
              >Nucleotide options:</strong
            >
          </p>
          <v-checkbox
            v-show="cur_job.job_type === 'nt'"
            v-model="skip_orf_search"
            label="Skip ORF search?"
          ></v-checkbox>
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
                v-show="cur_job.job_type === 'nt'"
                v-model.number="min_orf_length"
                label="Minimun ORF length"
                :rules="orfRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color="primary" v-on="on">Example</v-btn>
            </template>
            <v-list>
              <v-list-item @click="addExample('aa')">
                <v-list-item-title
                  >Example Amino Acids Submission</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="addExample('nt')">
                <v-list-item-title
                  >Example Nucleotide Submission</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text color="error" @click="clear">Clear</v-btn>
          <v-btn text color="green" @click="submit">Submit</v-btn>
        </v-card-actions>
      </v-col>
    </v-card>
    <v-overlay z-index="0" absolute :value="is_loading">
      <v-progress-circular
        color="rgba(153, 0, 0, 1)"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay @drop.prevent="addDropFile" v-model="has_dragover">
      <v-card color="rgba(153, 0, 0, 1)">
        <v-card-title
          >Drop a FASTA file containing genomic DNA, RNA, protein or gene
          sequences.</v-card-title
        >
      </v-card>
    </v-overlay>
    <error-dialog :error="error" @clear_error="clearError"></error-dialog>
  </div>
</template>

<script>
import ErrorDialog from "../ErrorDialog.vue";
const examples = require("../../util/examples.js");
const axios = require("axios").default;
export default {
  name: "SubmitCard",
  components: {
    ErrorDialog,
  },
  data() {
    return {
      min_orf_length: 60,
      skip_orf_search: false,
      n_seq: 0,
      example_type: null,
      bck_color: null,
      has_dragover: false,
      submission_blob_loading: false,
      is_loading: false,
      error: null,
      file_max_size: 32000000,
      cur_job: {
        job_name: "",
        email: "",
        email_when_notified: false,
        job_type: "aa",
        file_name: "",
        file_size: 0,
        file_extension: "",
        n_nucleotides: 60,
      },
      submission_blob: "",
      submission_file: null,
      orfRules: [
        (v) => v > 0 || "Minimun ORF length must be a positive integer",
      ],
      fileRules: [
        (v) =>
          v == null ||
          (this.cur_job.job_type === "aa"
            ? v.size <= 32000000
            : v.size <= 32000000) ||
          "FASTA file not valid, check size or sequence type",
      ],
      emailRules: [
        (v) =>
          v === "" ||
          v == null ||
          /.+@.+\..+/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    // adds the file to the submission via drag over
    dragOver() {
      clearTimeout(this.timerId);
      this.has_dragover = true;
      this.timerId = setTimeout(() => {
        this.has_dragover = false;
      }, 2000);
    },
    // removes the error if any
    clearError() {
      this.error = null;
    },
    // add a droped file
    addDropFile(e) {
      this.has_dragover = false;
      this.submission_file = e.dataTransfer.files[0];
    },
    // change in the type of job
    typeChange() {
      this.$refs.fileInput.validate();
    },
    //loads an example to the form
    addExample(exampleType) {
      this.submission_blob_loading = true;

      if (exampleType === "aa") {
        this.cur_job.job_name = "Example amino acid job";
        this.cur_job.job_type = exampleType;
        this.submission_blob = examples.aaExample;
      } else {
        this.cur_job.job_name = "Example nucleotide Job";
        this.cur_job.job_type = exampleType;
        this.submission_blob = examples.ntExample;
        this.skip_orf_search = true;
      }

      this.submission_blob_loading = false;
      this.example_type = exampleType;
    },
    //clears page and starts a new job
    clear() {
      this.$refs.form.reset();
      this.cur_job = {
        job_name: "",
        email: "",
        email_when_notified: false,
        job_type: "aa",
        file_name: "",
        file_size: 0,
        file_extension: "",
        n_nucleotides: 60,
      };
      this.submission_file = null;
      this.submission_blob = "";
      this.example_type = null;
      this.n_seq = 0;
      this.min_orf_length = 60;
      this.skip_orf_search = false;
    },
    // validate the input box
    validateFasta(sequence) {
      if (!sequence) {
        return false;
      } else if (sequence === "") {
        return false;
      } else if (sequence[0] !== ">") {
        return false;
      } else {
        let seqs = [];

        // split on newlines...
        let lines = sequence.split("\n");

        // itterate through lines
        let curHead = "";
        let curSeq = "";
        let isFirst = true;
        lines.forEach((x) => {
          if (x[0] === ">" && isFirst) {
            curHead = x.trim();
            isFirst = false;
          } else if (x[0] === ">" && !isFirst) {
            seqs.push({ id: curHead, seq: curSeq });
            // if (curSeq.length > 50000) {
            //   return false;
            // }
            curSeq = "";
            curHead = x.trim();
          } else {
            curSeq += x.trim();
          }
        });
        // last one
        seqs.push({ id: curHead, seq: curSeq });
        // if (curSeq.length > 50000) {
        //   return false;
        // }
        // check number of sequences
        // if (seqs.length > 100) {
        //   return false;
        // }
        if (seqs.length === 0) {
          return false;
        } else {
          this.n_seq = seqs.length;
          return true;
        }
      }
    },
    //convert to RNA
    convertRNA(blob) {
      // split on newlines...
      let newBloc = "";
      let lines = blob.split("\n");
      lines.forEach((x) => {
        if (x[0] !== ">") {
          x = x.replace(new RegExp("U", "g"), "T");
        }
        newBloc = newBloc + x + "\n";
      });
      return newBloc;
    },
    // submit the job
    submit() {
      this.is_loading = true;

      // validate form
      let formValid = false;
      if (this.$refs.form.validate()) {
        formValid = true;
      } else {
        // was an error
        this.error = this.error = {
          error: "There is an error in the job submission",
          status: "submission error",
        };
        this.is_loading = false;
        return;
      }

      let fileValid = false;
      let blobValid = false;
      let blob = null;

      // file take preference
      if (this.submission_file) {
        // check the size
        let fileSize = this.submission_file.size;
        if (fileSize > this.file_max_size) {
          // was an error
          this.error = {
            error: "File is too large. Please submit a smaller file.",
            status: "file error",
          };
          this.is_loading = false;
          return;
        }
        this.cur_job.file_name = this.submission_file.name;
        fileValid = true;
        this.cur_job.file_size = fileSize;
        // okay so we will try the blob then
      } else {
        // check if example
        if (this.example_type !== null) {
          if (this.example_type !== this.cur_job.job_type) {
            // was an error
            this.error = {
              error:
                "The example selected does not math the job type selected, please check your inputs and try again.",
              status: "input error",
            };
            this.is_loading = false;
            return;
          }
        }

        // validate blob
        blobValid = this.validateFasta(this.submission_blob);
        if (blobValid === false) {
          // was an error
          this.error = {
            error:
              "Please check the number of sequences in your input and/or the length of each sequence.",
            status: "fasta error",
          };
          this.is_loading = false;
          return;
        }

        // if nucleotide convert U to T
        if (this.cur_job.job_type === "nt") {
          this.submission_blob = this.convertRNA(this.submission_blob);
        }

        // create the blob
        blob = new Blob([this.submission_blob], {
          type: "text/plain",
        });

        // update size etc
        this.cur_job.file_size = blob.size;

        // check the size
        if (this.cur_job.file_size > this.file_max_size) {
          // was an error
          this.error = {
            error:
              "The number or length of sequences in your input is too large. Please submit a smaller number.",
            status: "fasta error",
          };
          this.is_loading = false;
          return;
        }

        // check job type
        if (this.cur_job.job_type === "aa") {
          this.cur_job.file_extension = "faa";
          this.cur_job.file_name = "cupp.faa";
        } else if (this.cur_job.job_type === "nt") {
          this.cur_job.file_extension = "fna";
          this.cur_job.file_name = "cupp.fna";
        }
      }

      // do the n nucleotides
      let nNucleotide = this.min_orf_length;
      if (this.skip_orf_search) {
        nNucleotide = 0;
      }

      // final
      let subJob = {
        email: this.cur_job.email,
        email_when_notified: this.cur_job.email_when_notified,
        file_name: this.cur_job.file_name,
        file_size: this.cur_job.file_size,
        job_name: this.cur_job.job_name,
        job_type: this.cur_job.job_type,
        n_nucleotide: nNucleotide,
      };

      if (formValid && fileValid) {
        axios
          .post(process.env.VUE_APP_SERVER + "/v1/jobs", subJob)
          .then((response) => {
            let job = response.data.job || null;
            if (job === null) {
              this.is_loading = false;
              this.error = {
                error: "There was an error submitting your job.",
                status: "submission error",
              };
              return;
            }
            let job_id = job.job_id || null;
            if (job_id === null) {
              this.is_loading = false;
              this.error = {
                error: "There was an error submitting your job.",
                status: "submission error",
              };
              return;
            }

            let formData = new FormData();
            formData.append(
              subJob.file_name,
              this.submission_file,
              subJob.file_name
            );
            axios
              .post(
                process.env.VUE_APP_SERVER +
                  "/v1/jobs/" +
                  job_id +
                  "/files?file_name=" +
                  subJob.file_name,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {
                this.$router.push({
                  name: "status",
                  params: { id: job_id },
                });
              })
              .catch((error) => {
                this.is_loading = false;
                if (error.response !== null) {
                  this.error = {
                    error: error.response.data.message,
                    status: error.response.status,
                  };
                } else {
                  this.error = {
                    error: "Error submitting job",
                    status: 500,
                  };
                }
              });
          })
          .catch((error) => {
            this.is_loading = false;
            if (error.response !== null) {
              this.error = {
                error: error.response.data.message,
                status: error.response.status,
              };
            } else {
              this.error = {
                error: "Error submitting job",
                status: 500,
              };
            }
          });
      } else if (formValid && blobValid) {
        axios
          .post(process.env.VUE_APP_SERVER + "/v1/jobs", subJob)
          .then((response) => {
            let job = response.data.job || null;
            if (job === null) {
              this.is_loading = false;
              this.error = {
                error: "There was an error submitting your job.",
                status: "submission error",
              };
              return;
            }
            let job_id = job.job_id || null;
            if (job_id === null) {
              this.is_loading = false;
              this.error = {
                error: "There was an error submitting your job.",
                status: "submission error",
              };
              return;
            }

            let formData = new FormData();
            formData.set(this.cur_job.file_name, blob, this.cur_job.file_name);

            axios
              .post(
                process.env.VUE_APP_SERVER +
                  "/v1/jobs/" +
                  job_id +
                  "/files?file_name=" +
                  subJob.file_name,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {
                this.$router.push({
                  name: "status",
                  params: { id: job_id },
                });
              })
              .catch((error) => {
                this.is_loading = false;
                if (error.response !== null) {
                  this.error = {
                    error: error.response.data.message,
                    status: error.response.status,
                  };
                } else {
                  this.error = {
                    error: "Error submitting job",
                    status: 500,
                  };
                }
              });
          })
          .catch((error) => {
            this.is_loading = false;
            if (error.response !== null) {
              this.error = {
                error: error.response.data.message,
                status: error.response.status,
              };
            } else {
              this.error = {
                error: "Error submitting job",
                status: 500,
              };
            }
          });
      }
      this.is_loading = false;
    },
  },
};
</script>

<style></style>
