const job_search_fields = [
  { field: "j_search", label: "Search", type: "text" },
  {
    field: "j_class",
    label: "Class",
    type: "select",
    select_field: "cazy_class",
  },
  {
    field: "j_fam",
    label: "Family",
    type: "select",
    select_field: "cazy_family",
  },
  {
    field: "j_sub_best",
    label: "Subfamily Best",
    type: "select",
    select_field: "cazy_subfamily_best",
  },
  {
    field: "j_sub_all",
    label: "Subfamily All",
    type: "select",
    select_field: "cazy_subfamily_all",
  },
  {
    field: "j_cupp",
    label: "CUPP Group",
    type: "select",
    select_field: "cupp_group",
  },
  {
    field: "j_branch",
    label: "CUPP Branch",
    type: "select",
    select_field: "cupp_branch",
  },
  {
    field: "j_ec_best",
    label: "EC Number Best",
    type: "select",
    select_field: "ec_serial_best",
  },
  {
    field: "j_ec_all",
    label: "EC Number All",
    type: "select",
    select_field: "ec_serial_all",
  },
  {
    field: "j_dom_length",
    label: "Domain Length",
    type: "slider",
    step: 10,
    select_field: "dom_length",
  },
  {
    field: "j_seq_length",
    label: "Sequence Length",
    type: "slider",
    step: 10,
    select_field: "seq_length",
  },
  {
    field: "j_coverage",
    label: "Coverage",
    type: "slider",
    step: 10,
    select_field: "coverage",
  },
  {
    field: "j_significance",
    label: "Significance",
    type: "slider",
    step: 1,
    select_field: "significance",
  },
];

const search_fields = [
  { field: "search", label: "Search", type: "text" },
  { field: "acc", label: "Accession", type: "text" },
  { field: "uni", label: "Uniprot", type: "text" },
  { field: "pubmed", label: "Pubmed", type: "text" },
  { field: "pdb", label: "PDB", type: "text" },
  {
    field: "ann_cazy_class",
    label: "CAZy Class",
    type: "select",
    select_field: "ann_cazy_class",
  },
  {
    field: "ann_cazy_family",
    label: "CAZy Family",
    type: "select",
    select_field: "ann_cazy_family",
  },
  {
    field: "ann_cazy_subfamily",
    label: "CAZy SubFamily",
    type: "select",
    select_field: "ann_cazy_subfamily",
  },
  {
    field: "ann_cupp_class",
    label: "CUPP Class",
    type: "select",
    select_field: "ann_cupp_class",
  },
  {
    field: "ann_cupp_family",
    label: "CUPP Family",
    type: "select",
    select_field: "ann_cupp_family",
  },
  {
    field: "ann_cupp_branch",
    label: "CUPP Branch",
    type: "select",
    select_field: "ann_cupp_branch",
  },
  {
    field: "ann_cupp_group",
    label: "CUPP Group",
    type: "select",
    select_field: "ann_cupp_group",
  },
  {
    field: "ann_ec_main_class",
    label: "EC Main Class",
    type: "select",
    select_field: "ann_ec_main_class",
  },
  {
    field: "ann_ec_subclass",
    label: "EC Subclass",
    type: "select",
    select_field: "ann_ec_subclass",
  },
  {
    field: "ann_ec_sub_subclass",
    label: "EC Sub-Subclass",
    type: "select",
    select_field: "ann_ec_sub_subclass",
  },
  {
    field: "ann_ec_serial",
    label: "EC Number",
    type: "select",
    select_field: "ann_ec_serial",
  },
  {
    field: "strain",
    label: "Strain",
    type: "select",
    select_field: "strain",
  },
  {
    field: "genome",
    label: "Genome",
    type: "select",
    select_field: "genome",
  },
  {
    field: "source",
    label: "Source",
    type: "select",
    select_field: "source",
  },
  {
    field: "tax_species",
    label: "Org. Species",
    type: "select",
    select_field: "tax_species",
  },
  {
    field: "tax_genus",
    label: "Org. Genus",
    type: "select",
    select_field: "tax_genus",
  },
  {
    field: "tax_family",
    label: "Org. Family",
    type: "select",
    select_field: "tax_family",
  },
  {
    field: "tax_order",
    label: "Org. Order",
    type: "select",
    select_field: "tax_order",
  },
  {
    field: "tax_class",
    label: "Org. Class",
    type: "select",
    select_field: "tax_class",
  },
  {
    field: "tax_phylum",
    label: "Org. Phylum",
    type: "select",
    select_field: "tax_phylum",
  },
  {
    field: "tax_kingdom",
    label: "Org. Kingdom",
    type: "select",
    select_field: "tax_kingdom",
  },
  {
    field: "tax_superkingdom",
    label: "Org. Super Kingdom",
    type: "select",
    select_field: "tax_superkingdom",
  },
  {
    field: "dom_length",
    label: "Domain Length",
    type: "slider",
    select_field: "dom_length",
  },
  {
    field: "seq_length",
    label: "Sequence Length",
    type: "slider",
    select_field: "seq_length",
  },
];

export function getJobSearchField(field) {
  for (let i = 0; i < job_search_fields.length; i++) {
    if (job_search_fields[i].field === field) {
      return job_search_fields[i];
    }
  }
  return null;
}

export function getSearchField(field) {
  for (let i = 0; i < search_fields.length; i++) {
    if (search_fields[i].field === field) {
      return search_fields[i];
    }
  }
  return null;
}

const cazy_fields = [
  {
    field: "ann_cazy_class",
    label: "Class",
    selected: [],
    filters: [],
  },
  {
    field: "ann_cazy_family",
    label: "Family",
    selected: [],
    filters: [],
  },
  {
    field: "ann_cazy_subfamily",
    label: "SubFamily",
    selected: [],
    filters: [],
  },
];

const gen_fields = [
  {
    field: "tax_kingdom",
    label: "Org. Kingdom",
    selected: [],
    filters: [],
    query: {
      has_genome: true,
    },
  },
  {
    field: "tax_species",
    label: "Org. Species",
    selected: [],
    filters: [],
    query: {
      has_genome: true,
    },
  },
  {
    field: "genome",
    label: "Genome",
    selected: [],
    filters: [],
    query: {
      has_genome: true,
    },
  },
];

const strain_fields = [
  {
    field: "tax_kingdom",
    label: "Org. Kingdom",
    selected: [],
    filters: [],
    query: {
      has_strain: true,
    },
  },
  {
    field: "tax_species",
    label: "Org. Species",
    selected: [],
    filters: [],
    query: {
      has_strain: true,
    },
  },
  {
    field: "strain",
    label: "Strain",
    selected: [],
    filters: [],
    query: {
      has_strain: true,
    },
  },
];

const cupp_fields = [
  {
    field: "ann_cupp_class",
    label: "CUPP Class",
    selected: [],
    filters: [],
  },
  {
    field: "ann_cupp_family",
    label: "CUPP Family",
    selected: [],
    filters: [],
  },
  {
    field: "ann_cupp_branch",
    label: "CUPP Branch",
    selected: [],
    filters: [],
  },
  {
    field: "ann_cupp_group",
    label: "CUPP Group",
    selected: [],
    filters: [],
  },
];

const ec_fields = [
  {
    field: "ann_ec_main_class",
    label: "EC Main Class",
    selected: [],
    filters: [],
  },
  {
    field: "ann_ec_subclass",
    label: "EC Subclass",
    selected: [],
    filters: [],
  },
  {
    field: "ann_ec_sub_subclass",
    label: "EC Sub-Subclass",
    selected: [],
    filters: [],
  },
  {
    field: "ann_ec_serial",
    label: "EC Number",
    selected: [],
    filters: [],
  },
];

const taxonomic_fields = [
  {
    field: "tax_superkingdom",
    label: "Org. Superkingdom",
    selected: [],
    filters: [],
  },
  {
    field: "tax_kingdom",
    label: "Org. Kingdom",
    selected: [],
    filters: [],
  },
  {
    field: "tax_phylum",
    label: "Org. Phylum",
    selected: [],
    filters: [],
  },
  {
    field: "tax_class",
    label: "Org. Class",
    selected: [],
    filters: [],
  },
  {
    field: "tax_order",
    label: "Org. Order",
    selected: [],
    filters: [],
  },
  {
    field: "tax_family",
    label: "Org. Family",
    selected: [],
    filters: [],
  },
  {
    field: "tax_genus",
    label: "Org. Genus",
    selected: [],
    filters: [],
  },
  {
    field: "tax_species",
    label: "Org. Species",
    selected: [],
    filters: [],
  },
];

const name_lookup = {
  search: "Search",
  uni: "Uniprot",
  pubmed: "PubMed",
  acc: "Accession",
  pdb: "PDB",
  ann_cazy_class: "Class",
  class: "Class",
  ann_cazy_family: "Family",
  fam: "Family",
  ann_cazy_subfamily: "Subfamily",
  sub: "Subfamily",
  ann_cupp_class: "CUPP Class",
  ann_cupp_fam: "CUPP Family",
  ann_cupp_branch: "CUPP Branch",
  branch: "CUPP Branch",
  ann_cupp_group: "CUPP Group",
  cupp: "CUPP Group",
  ann_ec_main_class: "EC Main Class",
  ann_ec_subclass: "EC Subclass",
  ann_ec_sub_subclass: "EC Sub-Subclass",
  ann_ec_serial: "EC Number",
  ec: "EC Number",
  tax_species: "Org. Species",
  tax_genus: "Org. Genus",
  tax_family: "Org. Family",
  tax_order: "Org. Order",
  tax_class: "Org. Class",
  tax_phylum: "Org. Phylum",
  tax_kingdom: "Org. Kingdom",
  tax_superkingdom: "Org. Super Kingdom",
  pdb_only: "PDB Only",
  char_only: "Characterized Only",
  cazy_only: "Cazy Only",
  full_length_only: "Full Length Only",
  rep_only: "Representatives Only",
  dom_length: "Domain Length",
  seq_length: "Sequence Length",
  genome: "Genome",
  strain: "Strain",
  source: "Source",
};

export function getFieldName(field) {
  return name_lookup[field] || "Invalid Field (" + field + ")";
}

//TODO: column widths
const selectedHeaders = {
  Job: [
    {
      text: "Header",
      align: "start",
      value: "seq_header",
      toolTip: "The provided header from the input FASTA file.",
      order: "seq_header",
    },
    {
      text: "CUPP Gr.",
      value: "cupp_group",
      align: "center",
      toolTip: "The CUPP predicted CUPP group.",
      order: "cupp_group_sort",
      width: "150px",
    },
    {
      text: "Family",
      value: "cazy_family",
      align: "center",
      toolTip: "The CUPP predicted CAZy family ",
      order: "cazy_family_sort",
    },
    {
      text: "Subfamily (Best)",
      value: "cazy_subfamily_best",
      align: "center",
      toolTip:
        "The CUPP predicted CAZy subfamily. Only the subfamily which is mostly abundant in the CUPP group is included.",
      order: "cazy_subfamily_best_sort",
    },
    {
      text: "EC (Best)",
      value: "ec_serial_best",
      align: "center",
      toolTip:
        "The CUPP predicted EC numbers. Only those EC numbers which are mostly abundant in the CUPP group is included.",
      order: "ec_serial_best_sort",
    },
    {
      text: "Start",
      value: "dom_start",
      align: "center",
      toolTip: "The start position of the protein domain",
      order: "dom_start",
    },
    {
      text: "End",
      value: "dom_end",
      align: "center",
      toolTip: "The end position of the protein domain",
      order: "dom_end",
    },
    {
      text: "Domain Length",
      value: "dom_length",
      align: "center",
      toolTip: "The number of amino acids in the protein domain",
      order: "dom_length",
    },
    {
      text: "Significance",
      value: "significance",
      align: "center",
      toolTip:
        "A score of 1 indicate that the region of the protein below to the target family. If the score is greater, the value indicates how many fold more evidence is observed than needed.",
      order: "significance",
    },
  ],
  Browse: [
    {
      text: "Accession",
      align: "start",
      value: "acc",
      toolTip: "The accession id of this sequence",
      order: "acc",
    },
    {
      text: "CUPP Group",
      align: "center",
      value: "ann_cupp_group",
      toolTip:
        "The CUPP group in which this particular domain of the CAZyme belongs",
      order: "ann_cupp_group_sort",
      width: "150px",
    },
    {
      text: "Family",
      align: "center",
      value: "ann_cazy_family",
      toolTip: "The CAZy family of the current domain region of the protein",
      order: "ann_cazy_family_sort",
    },
    {
      text: "Subfamily",
      align: "center",
      value: "ann_cazy_subfamily",
      toolTip: "The CAZy subfamily provided by CAZy.org.",
      order: "ann_cazy_subfamily_sort",
    },
    {
      text: "EC Number",
      align: "center",
      value: "ann_ec_serial",
      toolTip: "The EC number provided from CAZy.org for this particular entry",
      order: "ann_ec_serial_sort",
    },
    {
      text: "Strain",
      value: "strain",
      align: "start",
      toolTip: "Strain that this domain.",
      order: "strain",
    },
    {
      text: "Genome",
      value: "genome",
      align: "start",
      toolTip: "Genome this sequence was retrieved from.",
      order: "genome",
    },
    {
      text: "Source",
      value: "source",
      align: "center",
      toolTip:
        "State the database source of this entry. Most entries originates from CAZy.org, but some CUPP groups have also got members from NCBI Genbank directly upon a BLAST expansion of the family, especially to favor the smaller families.",
      order: "source",
    },
    {
      text: "Org Species",
      align: "start",
      value: "tax_species",
      toolTip:
        "The taxonomic species, obtained using the NCBI Taxonomy ID of the entry",
      order: "tax_species",
    },
  ],
};

export function getSelectedJobTableHeader(value) {
  for (let i = 0; i < selectedHeaders["Job"].length; i++) {
    if (selectedHeaders["Job"][i].value === value) {
      return selectedHeaders["Job"][i];
    }
  }
  return null;
}

export function getSelectedBrowseTableHeader(value) {
  for (let i = 0; i < selectedHeaders["Browse"].length; i++) {
    if (selectedHeaders["Browse"][i].value === value) {
      return selectedHeaders["Browse"][i];
    }
  }
  return null;
}

const tableHeaders = {
  Job: [
    {
      text: "Header",
      align: "start",
      value: "seq_header",
      toolTip: "The provided header from the input FASTA file.",
      order: "seq_header",
    },
    {
      text: "CUPP Gr.",
      value: "cupp_group",
      align: "center",
      toolTip: "The CUPP predicted CUPP group.",
      order: "cupp_group_sort",
    },
    {
      text: "Family",
      value: "cazy_family",
      align: "center",
      toolTip: "The CUPP predicted CAZy family ",
      order: "cazy_family_sort",
    },
    {
      text: "Subfamily (Best)",
      value: "cazy_subfamily_best",
      align: "center",
      toolTip:
        "The CUPP predicted CAZy subfamily. Only the subfamily which is mostly abundant in the CUPP group is included.",
      order: "cazy_subfamily_best_sort",
    },
    {
      text: "EC (Best)",
      value: "ec_serial_best",
      align: "center",
      toolTip:
        "The CUPP predicted EC numbers. Only those EC numbers which are mostly abundant in the CUPP group is included.",
      order: "ec_serial_best_sort",
    },
    {
      text: "Start",
      value: "dom_start",
      align: "center",
      toolTip: "The start position of the protein domain",
      order: "dom_start",
    },
    {
      text: "End",
      value: "dom_end",
      align: "center",
      toolTip: "The end position of the protein domain",
      order: "dom_end",
    },
    {
      text: "Domain Length",
      value: "dom_length",
      align: "center",
      toolTip: "The number of amino acids in the protein domain",
      order: "dom_length",
    },
    {
      text: "Significance",
      value: "significance",
      align: "center",
      toolTip:
        "A score of 1 indicate that the region of the protein below to the target family. If the score is greater, the value indicates how many fold more evidence is observed than needed.",
      order: "significance",
    },
    {
      text: "Coverage",
      value: "coverage",
      align: "center",
      toolTip: "Percentage of covered amino acids by the current domain.",
      order: "coverage",
    },
    {
      text: "CAZyme Class",
      value: "cazy_class",
      align: "center",
      toolTip: "The CUPP predicted CAZy class",
      order: "cazy_class_sort",
    },
    {
      text: "CUPP Branch",
      value: "cupp_branch",
      align: "center",
      toolTip: "The CUPP predicted CUPP branch",
      order: "cupp_branch_sort",
    },
    {
      text: "Sequence Length",
      value: "seq_length",
      align: "center",
      toolTip: "The length of the amino acid sequence",
      order: "seq_length",
    },
    {
      text: "Subfamily (All)",
      value: "cazy_subfamily_all",
      align: "center",
      toolTip:
        "The CUPP predicted CAZy subfamily, providing all the subfamilies of the assigned group",
      order: "cazy_subfamily_all_sort",
    },
    {
      text: "EC (Full)",
      value: "ec_serial_all",
      align: "center",
      toolTip:
        "The CUPP predicted EC numbers, providing all the EC numbers of the assigned group",
      order: "ec_serial_all_sort",
    },
  ],
  Browse: [
    {
      text: "Accession",
      align: "start",
      value: "acc",
      toolTip: "The accession id of this sequence",
      order: "acc",
    },
    {
      text: "CUPP Group",
      align: "center",
      value: "ann_cupp_group",
      toolTip:
        "The CUPP group in which this particular domain of the CAZyme belongs",
      order: "ann_cupp_group_sort",
    },
    {
      text: "Family",
      align: "center",
      value: "ann_cazy_family",
      toolTip: "The CAZy family of the current domain region of the protein",
      order: "ann_cazy_family_sort",
    },
    {
      text: "Subfamily",
      align: "center",
      value: "ann_cazy_subfamily",
      toolTip: "The CAZy subfamily provided by CAZy.org.",
      order: "ann_cazy_subfamily_sort",
    },
    {
      text: "EC Number",
      align: "center",
      value: "ann_ec_serial",
      toolTip: "The EC number provided from CAZy.org for this particular entry",
      order: "ann_ec_serial_sort",
    },
    {
      text: "Strain",
      value: "strain",
      align: "start",
      toolTip: "Strain that this domain.",
      order: "strain",
    },
    {
      text: "Genome",
      value: "genome",
      align: "start",
      toolTip: "Genome this sequence was retrieved from.",
      order: "genome",
    },
    {
      text: "Source",
      value: "source",
      align: "center",
      toolTip:
        "State the database source of this entry. Most entries originates from CAZy.org, but some CUPP groups have also got members from NCBI Genbank directly upon a BLAST expansion of the family, especially to favor the smaller families.",
      order: "source",
    },
    {
      text: "Org Species",
      align: "start",
      value: "tax_species",
      toolTip:
        "The taxonomic species, obtained using the NCBI Taxonomy ID of the entry",
      order: "tax_species",
    },
    {
      text: "CAZyme Class",
      align: "center",
      value: "ann_cazy_class",
      toolTip: "The CAZy class that this sequence belongs too",
      order: "ann_cazy_class_sort",
    },
    {
      text: "CUPP Branch",
      align: "center",
      value: "ann_cupp_branch",
      toolTip:
        "The CUPP branch in which this particular domain of the CAZyme belongs",
      order: "ann_cupp_branch_sort",
    },
    {
      text: "PDB",
      value: "pdbs",
      align: "center",
      toolTip:
        "The PDB accession number to inspect the structure of the given entry",
      order: "pdbs",
    },
    {
      text: "SignalP",
      value: "signal_peptide",
      align: "center",
      toolTip: "Cut point of signal peptide as determined by SignalP",
      order: "signal_peptide",
    },
    {
      text: "Description",
      value: "desc",
      align: "start",
      toolTip: "This is the header of this sequence",
      order: "desc",
    },
    {
      text: "Sequence Length",
      value: "seq_length",
      align: "center",
      toolTip: "The length of the full length protein",
      order: "seq_length",
    },
    {
      text: "Fragment",
      value: "is_fragment",
      align: "center",
      toolTip:
        "Indicates that the entry may not be full length, provided from CAZy.org",
      order: "is_fragment",
    },
    {
      text: "Domain Start",
      value: "dom_start",
      align: "center",
      toolTip: "",
      order: "dom_start",
    },
    {
      text: "Domain End",
      value: "dom_end",
      align: "center",
      toolTip: "",
      order: "dom_end",
    },
    {
      text: "Domain Length",
      value: "dom_length",
      align: "center",
      toolTip: "",
      order: "dom_length",
    },
    {
      text: "Member Score",
      value: "ann_member_score",
      align: "center",
      toolTip: "Relative score for this member in this CUPP Group",
      order: "ann_member_score",
    },
    {
      text: "Taxid",
      value: "taxonomy_id",
      align: "center",
      toolTip: "The NCBI Taxonomy Identification number.",
      order: "taxonomy_id",
    },
    {
      text: "Uniprot ID",
      value: "uniprot",
      align: "center",
      toolTip: "Uniprot number associated with this sequence.",
      order: "uniprot",
    },
    {
      text: "Pubmed ID",
      value: "pubmeds",
      align: "center",
      toolTip: "Pubmed ID associated with this sequence if known.",
      order: "pubmeds",
    },
    {
      text: "Org Class",
      value: "tax_class",
      align: "center",
      toolTip:
        "The taxonomic class, obtained using the NCBI Taxonomy ID of the entry",
      order: "tax_class",
    },
    {
      text: "Org Phylum",
      value: "tax_phylum",
      align: "center",
      toolTip:
        "The taxonomic phylum, obtained using the NCBI Taxonomy ID of the entry",
      order: "tax_phylum",
    },
  ],
};

export function getJobTableHeader(value) {
  for (let i = 0; i < tableHeaders["Job"].length; i++) {
    if (tableHeaders["Job"][i].value === value) {
      return tableHeaders["Job"][i];
    }
  }
  return null;
}

export function getBrowseTableHeader(value) {
  for (let i = 0; i < tableHeaders["Browse"].length; i++) {
    if (tableHeaders["Browse"][i].value === value) {
      return tableHeaders["Browse"][i];
    }
  }
  return null;
}

const domainHeaders = {
  Job: [
    {
      text: "Header",
      align: "start",
      value: "sequence_header",
      toolTip: "The provided header from the input FASTA file.",
      order: "sequence_header",
    },
    {
      text: "CUPP Gr.",
      value: "cupp_group",
      align: "center",
      toolTip: "The CUPP predicted CUPP group.",
      order: "cupp_group_sort",
    },
    {
      text: "Family",
      value: "cazy_family",
      align: "center",
      toolTip: "The CUPP predicted CAZy family ",
      order: "cazy_family_sort",
    },
    {
      text: "Subfamily (Best)",
      value: "cazy_subfamily_best",
      align: "center",
      toolTip:
        "The CUPP predicted CAZy subfamily. Only the subfamily which is mostly abundant in the CUPP group is included.",
      order: "cazy_subfamily_best_sort",
    },
    {
      text: "EC (Best)",
      value: "ec_serial_best",
      align: "center",
      toolTip:
        "The CUPP predicted EC numbers. Only those EC numbers which are mostly abundant in the CUPP group is included.",
      order: "ec_serial_best_sort",
    },

    {
      text: "Start",
      value: "range_start",
      align: "center",
      toolTip: "The start position of the protein domain",
      order: "range_start",
    },
    {
      text: "End",
      value: "range_end",
      align: "center",
      toolTip: "The end position of the protein domain",
      order: "range_end",
    },
    {
      text: "Domain Length",
      value: "length",
      align: "center",
      toolTip: "The number of amino acids in the protein domain",
      order: "length",
    },
    {
      text: "Significance",
      value: "significance",
      align: "center",
      toolTip:
        "A score of 1 indicate that the region of the protein below to the target family. If the score is greater, the value indicates how many fold more evidence is observed than needed.",
      order: "significance",
    },
    {
      text: "Coverage",
      value: "coverage",
      align: "center",
      toolTip: "Percentage of covered amino acids by the current domain.",
      order: "coverage",
    },
    {
      text: "Sequence Length",
      value: "sequence_length",
      align: "center",
      toolTip: "The length of the amino acid sequence",
      order: "sequence_length",
    },
  ],
  Browse: [
    {
      text: "Accession",
      align: "start",
      value: "acc",
      toolTip: "The accession id of this sequence",
    },
    {
      text: "CUPP Group",
      align: "center",
      value: "ann_cupp_group",
      toolTip:
        "The CUPP group in which this particular domain of the CAZyme belongs",
    },
    {
      text: "CUPP Branch",
      align: "center",
      value: "ann_cupp_branch",
      toolTip:
        "The CUPP branch in which this particular domain of the CAZyme belongs",
    },
    {
      text: "CAZyme Class",
      align: "center",
      value: "ann_cazy_class",
      toolTip: "The CAZy class that this sequence belongs too",
    },
    {
      text: "Family",
      align: "center",
      value: "ann_cazy_family",
      toolTip: "The CAZy family of the current domain region of the protein",
    },
    {
      text: "Subfamily",
      align: "center",
      value: "ann_cazy_subfmaily",
      toolTip: "The CAZy subfamily provided by CAZy.org.",
    },
    {
      text: "EC Number",
      align: "center",
      value: "ann_ec_serial",
      toolTip: "The EC number provided from CAZy.org for this particular entry",
    },
    {
      text: "Domain Start",
      value: "dom_start",
      align: "center",
      toolTip: "",
    },
    {
      text: "Domain End",
      value: "dom_end",
      align: "center",
      toolTip: "",
    },
    {
      text: "Domain Length",
      value: "dom_length",
      align: "center",
      toolTip: "",
    },
    {
      text: "Sequence Length",
      value: "seq_length",
      align: "center",
      toolTip: "The length of the full length protein",
    },
  ],
};

const summaryOptions = {
  Job: [
    { text: "Class", field: "cazy_class" },
    { text: "Family", field: "cazy_family" },
    { text: "Subfamily (Best)", field: "cazy_subfamily_best" },
    { text: "Subfamily (Full)", field: "cazy_subfamily_all" },
    { text: "CUPP Br.", field: "cupp_branch" },
    { text: "CUPP Gr.", field: "cupp_group" },
    { text: "EC (Best)", field: "ec_serial_all" },
    { text: "EC (Full)", field: "ec_serial_best" },
  ],
  Browse: [
    { text: "CUPP Br.", field: "ann_cupp_branch" },
    { text: "CUPP Gr.", field: "ann_cupp_group" },
    { text: "EC No.", field: "ann_ec_serial" },
    { text: "Subfamily", field: "ann_cazy_subfamily" },
    { text: "Family", field: "ann_cazy_family" },
    { text: "Org Class", field: "tax_class" },
    { text: "Org Phylum", field: "tax_phylum" },
    { text: "Org Species", field: "tax_species" },
    { text: "Strain", field: "strain" },
    { text: "Genome", field: "genome" },
  ],
};

// const pdb_representations = [
//   { name: "Cartoon", value: "cartoon" },
//   { name: "Ball and Stick", value: "ball+stick" },
// ];
// const pdb_colours = [
//   { name: "B Factor", value: "bfactor" },
//   { name: "Chain", value: "chainid" },
//   { name: "Secondary Structure", value: "sstruc" },
// ];

export {
  job_search_fields,
  search_fields,
  cazy_fields,
  strain_fields,
  gen_fields,
  taxonomic_fields,
  name_lookup,
  selectedHeaders,
  tableHeaders,
  summaryOptions,
  domainHeaders,
  cupp_fields,
  ec_fields,
};
