var aaExample =
  "" +
  ">EAA29705.1 hypothetical protein [Neurospora crassa]\n" +
  "MVPSLQTVGHILVTALAIIPFAFADLTTTIDPSSNWGTWEGWGTSLAWWAATFGTRDDLADIFFTTQTTNLNGVSLPGLGFNIARYNAGASSWNSINGTSMVVSPKMIPSRQIEGFWIDWASTSPSSASWNWSADSAQRAMLSKARSRGANILELFSNSPMWWMCNNHNPSGSDDGSSDNLQSWNYQSHAVYMATVAKYFADHWSTTFTSVDPFNEPSANWWNGKTGTQEGCHFDVSTQATVLNYLRAELDSRGLSSTVISASDESYYDQAAKTLQNLGSTALGKIARVNVHGYQYGSGRRDSVRSLATQYSKKLWQSEYGEGDATGRDLASNLLLDLRWLQPTAWVYWQALDGGGWGLVDADNDKKTIGQPTQKYFVLAQFARHVKPGMRILDGGSDYATAAYDAATKKLVIVAVNWGSAQYLNFDLSRFKTAGVNGAVVKRWATQIGASTGKRYVAASDTYIRGTKFWSYFEKEMVQTFEVEGVVL\n" +
  "\n" +
  ">BAO73305.1 LcGUS30 [Lactobacillus brevis subsp. coagulans]\n" +
  "MKKFQHEFWTATSGDLTQRRTPLATPDYQSTSAAAVKIIIDPADRHQPWLGGGAAITDSAAYLLWSVMSAEQRRALLTELFDPDQGGFSSVRVPLGSCDFQSQDFYTYDDVPYGEHDQKLEQFSIGTGQPGAPDATKDLKHIVPVLQEILAINPAVKVIASPWSAPAWMKNTGHLTHGGHLRFGEFTGNGYTEENRFEYIYAQYFIRYIEAYQKLGIPIYGLTIQNEPSNAAHWPAMIWTVPQLADFGYRYLRPALNHSFPDTKLYLLDDSFHALTKPITAEVTPEQAAAFDGLAVHTYSGPYDNLYHANRAYPNWSTIMTERRCMMTDTPEEAAHIMFGIIGNWLVHNGLSMITLWNLALDERGLPNAADSTGREGVVTIDHTTGKVQRNLEYFMLRNFGQDVSVGATVIGSTNYTRDGYTGGLGSVAFLGTAGDIAAHLYNPTAQPIQAAVTINGNGANWQLVTVPPYGTVTLHKSDAPLNTTNVPVDDEFPLNPTPANHSDVAPGQH\n" +
  "\n" +
  ">BAE55732.1 unnamed protein product [Aspergillus oryzae RIB40]\n" +
  "MSNSNPPLITHIYTADPSAHVFNNKVYIYPSHDRETDIQFNDNGDQYDMVDYHVFSMDSISGPVTDHGVVLTLSDVPWASKQLWAPDAATKDGKYYLYLPARDHDGIFRIGVAVAEKPEGPFTAQKSFIAGSYSIDPASFVDDDGKAYLYFGGIWGGQLQCWSKDAGAAGEWVFDASKTGPQEPSGENELALHPRVAQLTDDMLGFETPVQELVILDQDGVSPLKADDHERRFFEASWMHKYNGTYYFSYSTGDTHYIVYATGESPFGPFRYRGRILEPVLGWTTHHSIVEFGGRWWIFYHDCELSKGVSHLRSVKVREIGYDDQGDIYLVD\n" +
  "\n" +
  ">AAP64786.1 Sequence 2 from patent US 6555335\n" +
  "MKSSISVVLALLGHSAAWSYATKSQYRANIKINARQTYQTMIGGGCSGAFGIACQQFGSSGLSPENQQKVTQILFDENIGGLSIVRNDIGSSPGTTILPTCPATPQDKFDYVWDGSDNCQFNLTKTALKYNPNLYVYADAWSAPGCMKTVGTENLGGQICGVRGTDCKHDWRQAYADYLVQYVRFYKEEGIDISLLGAWNEPDFNPFTYESMLSDGYQAKDFLEVLYPTLKKAFPKVDVSCCDATGARQERNILYELQQAGGERYFDIATWHNYQSNPERPFNAGGKPNIQTEWADGTGPWNSTWDYSGQLAEGLQWALYMHNAFVNSDTSGYTHWWCAQNTNGDNALIRLDRDSYEVSARLWAFAQYFRFARPGSVRIGATSDVENVYVTAYVNKNGTVAIPVINAAHFPYDLTIDLEGIKKRKLSEYLTDNSHNVTLQSRYKVSGSSLKVTVEPRAMKTFWLE\n" +
  "\n" +
  ">AAU40201.1 arabinan endo-1,5-alpha-L-arabinosidase AbnA [Bacillus licheniformis DSM 13 = ATCC 14580]\n" +
  "MLKTSKFERRTTVAKTIISGFILFFLLIFSAAEPTSAAFWDTKGDNIIHDPSMIKEGNTWYTFGTGIGNGIRVIKSTDGKTWSAAPSIFATPLSWWKKYVPNHEENQWAPDISYYNGRYWLYYAVSAFGSNTSAIGLASTDRISSGNWRDDGLVIRTTTSNNYNAIDPELVIDKEGNPWLSFGSFWSGIKLTKLDKKTMKPTGKVYSIASRPNNGGAVEAPSITYKNGYYYLFVSFDKCCQGVNSTYKIAYGRSTKITGPYYDKSGKKMTEGGGTILDSGNDQWKGPGGADIVNGNIIVRHGYDALDNGAPKLLINDLNWDSKGWPKY\n";

var ntExample =
  "" +
  ">XM_001391539.2 Aspergillus niger CBS 513.88 endo-arabinase, mRNA\n" +
  "ATGAAAGGCCTCCCGTCACTGCTGAACAGCAGCAGCACCAAAAAATGGACACAATCCACCCCATCCACAGGCGAGGGTGAGAATGAGAGTGGTAGTACTATATCACCCACCAAGCCGCCCGAGCCATCCACACCCCCAGGAGGCTCAAACACCTCCATGATCTGGACGCGGAAGAAGATAATATGGCTCATCACCCTGATAGCTCTAAGCGTAGCCATAATCGTGGTGGTGATAACCGTCCCAGTAGTTCTCCTGCTGGACGATAAACACAACGACGATCCCAGCTACTACAACAGCGCCAACCGTCCAGTCCGCGTCGTGCATGACTTCCCCGACCCGGGTCTAATCCAGGTGAACAGCACGTGGTATGCATACGCTACAGTCGCCACACCGGACAATCCTGACGTCCCTCACGTCCCGGTATCGACATCGCGGAACTTTAGCAGCTGGACCTGGTTGCAGGGATACGATGTTATGCCTGCAATTAGCAGTTGGGAAACGAATATGAACCAGTACGCTCCGGACGTGATTCAACGCAAAGACGGCCACTTCGTCCTCTACTACTCAGGCGAACTAAAAGACTGGCTCCGCCACCACTGCGTCGGCGCCGCTGTCTCCAACGGCACAAGTCCCCTAGGACCATACATCCCCCACAACACCACCCTCGCCTGTCCCCGCGACCACGGCGGTGCCATCGACCCCGCCCCCTTCAGAGACGTCAACGGGACCCTCTACGTCGTGTACAAAGTCGACGGCAACAGCATCGGCCACGGCGGAGGCTGCAACAACGGCAAGAAACCCATCGTCTCCACGCCCATCATGCTCCAACAACTCAAAGACGATGGAGTCACCCCCGTCGGTGATCCCGTTGAAATTCTAACCAACGAAAAAGTCGATGGGCCGTTAGTCGAGGCTCCGGCCATTATTCGAACTGATCGCGGCATCTATTATCTGTTCTTCTCTTCGCATTGCTTTACATCGTCGAAGTATAGTGTTAAGTATGCATGGTCAACATCGTTAAAGGGGCCGTATACCAGGGCTGAGAGGCCCTTGTTCCGGTCTGGGGACTTTGGGCTAAAGTCTCCTGGCGGTGCGACGGCTTCGGTTGATGGGTCTAGGATTGTTTTTCATGCCTTTTGTGGGGATTATAGATGTATGTATGCCGCGGCGATGAATATTACGGCTAATTATACGATTTTGCCGGCGGCGCTTTGA\n" +
  "\n" +
  ">XM_001400147.2 Aspergillus niger CBS 513.88 arabinan endo-1,5-alpha-L-arabinosidase C, mRNA\n" +
  "TTCTTTGTTTTTTTTCCCCTCCTCCATTCCCCTTTACCCTCCTCTTCCCTTTTCTTCTTTTCCCCTCTGGAGGATACCCCCTTATCCGCTATGCTTTCGTTTGTCTTGCTTCTTTGTGTAGCGTTGGTCAACGCCTACTCAGACCCGGGTGCATGCTCGGGAACCTGCTGGGCACACGACCCCAATGTCATTCGCCGTGTGTCGGATGGAACCTACTTTCGTTTCTCGACAGGTGGTGGTGTCCATATCTCCTCTGCCAGCGCCATCACTGGTCCCTGGACTGATCTCGGGTATGCACTGCCTAACGGATCCATCGTTACAGTGGGAAATGCCTCCAACCTTTGGGCTCCGGACGTACACTACGTAGATGGCACATACTACATGTACTATGCTAGCTCTACCCTGGGCAGCCGGGATTCCACCATTGGAGTTGCAACCTCCACCACCCTGGAAGCCGACTCCTGGACCGACCACGGCGAGATTGGTGTCACCTCGTCTTCGTCCACCCCTTACAACGCCATTGACCCCAACTGGATCACCATTGGCAGCACCCCCTATCTCCAATTCGGCTCCTACTGGCAAGGTCTCTACCAGGTGGAAATGACCGACTCCCTGAGCGCCAGCAGCAGCACGCCCACTAACTTGGCCTACAACGCATCGGGGAACCATGCTATCGAGGCTTCTTACCTGTACGAGTACGGAGGCTACTACTACCTCACCTTCTCGTCCGGCAAGGCTCAGGGGTACACGACCTCCCTGCCTGCCCAGGGCGATGAGTACCGCATTGTCGTTTGCCGGTCCAAGACTGGAACGGGTAACTTTGTCGACAAGGATGGTGTTTCATGCCTGAACAGCGGCGGAACCACCGTTCTGGCCAGCCACGACTATGTCTACGGCCCTGGTGGACAGGGTATCATCAACACCACCAGCCACGGTATTGTCGTCTACTACCACTATGCCAACAAGAACATTGGCCTGGCTGTCGATGACTACCAGTTCGGCTGGAACACGCTCACCTGGACTGATGGGTGGCCTGTTGTGGCGTGATTTCATTCTTCTTCTTCTTCTATACATACATACATTTTACCGGCGGCCCAGAAATGGGCTCGGTAATATTTGCATGATGATACCTGCGTTCGATTGTATTTAGCTAATTACGTTAATTCGTAATGTTTTATATTGCGATTTAATATATACATATTGAGACATAATTTGTTTGAGAGATTTGTGTAGAATGTCTGTAGAAAATATGAAGCGTCTCGAATTAAGAATGTCAACAACAGGAGTCACTAGAAGGGGCAGTTTAATGATGAAATTACTATCACCCTACTTGATCCTTAATTCCGTCACTCCCCCTTTGACATTCCCCATCCCATCCCCATACAAAAAGCAAAAATGCCACCCCATAAAAAAAAAGAGCCTAGACAACAAACAAACAAACATAACATACCATCCTACGGACCGCACCTAAACTCAATTGTGGAACAATCAGGATTCCTCGGTATTTTGCCCCGAAGAATCTACTACATACCCACATTACTATGCACATTGCCATTGCCACCCAAGCGAAACAAACAACTCACCACATACTGATTAATTAAACGCACAATCCCCAATGCAA\n" +
  "\n" +
  ">XM_001399243.2 Aspergillus niger CBS 513.88 arabinan endo-1,5-alpha-L-arabinosidase B, mRNA\n" +
  "ATGACCCCCAACTGGTCCAAACTATGGACCTTCATCGCCAACCCCAAAGACCCCAGTTCTAGCTCCCCATCACCATATACACTCCGTCGCATAATCAAATCCCTCTCCCTTCTCACCGTCTTCTCCATCTTCCTCTACGCCCTCTACATTCACTTCCAGCCCTCCATTATCCCACAAACCCCAGACCTCCCAGACCCAGACCTCCCTCCCTCCCCAGAAGACTCATACAAATCCATCTACGGCTACCCCCCAACCAACCCCACCATCCCTCCCCTGCACATCCACGACCCCAGCATCCTCTACGACCTGCCCACAAACACCTACTACGCCTACGGCTCCGGCCCTCACATCCCCATCCACTCCGCCCCGACCCTCCAAGGACCATGGACCAAAGTCGGCACCGTCCTCGATGCAGATAGTATTCTACCAAAGGGTGATCGCAAAGCCCCATGGGCACCGACCGCCCTCGTCCACGACGGTACCTTCTACGTCTTTTACGCGACGAGCCATAGTGGATGTCGCGATAGCGCTATCGGCGTGGCTACCTCTACTTCTCCGGGCCCTGGGGGATGGGAGGACCACGGGGCGATAGCTATCTCCGGACGAGGGGAGAGGGGGAAGGAGTACCCGTTTGATAGGGCGAATGCGATTGATGTTAGTGTTGTTAAAGGAAAAGGGAGAGGATACATGACCTTCGGCAGTTTCTGGACGGGGATATGGCAAGTCCCGCTGAAGCCTAACCTGCTTCATATGGACAAGCAGGGAGAAGAAGAAAAAAGGGTTAAACATCTCGCCCACGAGCCCGCAGCCATCCACCCACCAACCAAAAAAGCAGATGGATTATGTGGTGATACGACGGGCATGCATCCCATCGAGGGGGCGTTTATATCCTATCATGAGCCGTGGTGGTATTTGTGGTTTAGTTGGGGGAAGTGCTGTCATTTTGATCCGGAGAAATTGCCGAGGGCTGGTCTTGAATACAGCATCCGCGTCGGACGATCAAGTTCTCCTCAAGGTCCATTCGTGGATAAAGAAGGGAAAGATCTAGTGGATGGAGGAGGGGAGATTGTGTATGGGTCGAATGGGGATGTTTATGCGCCTGGGGGACAGGGCGTGTTGAGTGGGGAGGTGGAGGGAGATGTGCTTTATTATCATTATTTGAATATATCTGTGGGGTATGAGTTTAAGGAAGCACGGCTGGGATATAATTATTTGAAGTATGTGGATGGGTGGCCGGTTCCACTGTGA\n" +
  "\n" +
  ">XM_001389919.2 Aspergillus niger CBS 513.88 glucan endo-1,6- -glucosidase BGN16.3, mRNA\n" +
  "ATGAGTTGGATGTTGTGGTATACTATAGCTGGCCTAGCATCTCTAGGCCATGCTTCTCTTGAGAAACGCGCCACCGATGCATCTGCGTACTGCTCCGATTCATCGGGTACCTACAAGCTCTCGTCGATCACGGCCCCAGTTCAGGGAAGTGGCAGCCCGGGCTCAGCATCCACCTGGAAGCTAAGTATTGACGATACATCATCCGGTCACAAGCAGGCAATCACTGGCTTCGGGGCTGCTGTGACCGATGCGACCGTCACGTCTTTTAACACCTTGTCGAGCTCTACCCTCCAACAGCTCCTCAATGAGTTGATGACTTCCGATGGAGCTAATTTCTCTCTCATGCGCCATACCATCGGCGCGTCTGATCTGTCCGGTGATCCGGTCTACACCTATGATGACAACGGAAACGAAGCAGACCCCTCACTTTCCGGCTTCGGTCTCGGCGACCGTGGCACTGCTATGGCGGAAATGTTGGCCACGATGAAATCGTTGCAGTCAGACCTGAAGGTGCTTGGGTCCCCGTGGAGTGCGCCCGGATGGATGAAATTGAATGGCGTGATTGATGGCAACGCGACTGATAACAACCTTAACGATGGCTACCTTACCAGTGGTGGCACGGGAAGCACCGGCTACTCCAGTGCTTTCGCGCAGTACTTCGTGAAATACATCCAAGCGTACGAAGCATTAGGAGCTACGGTCGATGCCATTACGATTCAGAATGAGCCGTTAAATAGTCAATCTGGATATCCCACCATGTATGTTTACGACTATGAGTCTGCGCAGCTGATCCAAAAATACATTGGACCGGCTCTTGCCGACGCTGGACTGAGCACGGAAATCTGGGCCTTCGATCACAATACTGATCACGCATCGTACCCTCAGACTGTGCTTGATGAAGCAAGTCAATACGTCGACACGGTAGCCTGGCACTGTTATGCAAAGGATGTTGACTGGACTGTTCTCAGCCAGTTCTACAGCGCCAACCCTGGGGTCAAGCAATATATGACCGAATGCTGGACACCAGCTTCCGGATCATGGCACCAGGCTGCTGATTTTACCATGGGCCCTCTGCAGAACTATGCGTCGGGGGTCATGGCGTGGACTCTGGGCACGAACACTGACGACGGTCCGCATTTGACCAGTGGTGGATGCGACGCCTGTCAGGGCTTGGTCACCATCAACGATGATGACAGCTATACTCTTAATACTGCCTACTACATGATGGCACAGTTTAGCAAGTTCATGCCTCCTGGCGCGACCGTGTTGAACGGAACGGGGAGCTATACCTACTCGGGTGGCGATGGCGTACAGTCGGTGGCGTCCCTGAACCCTGATGGAACGCGCACAGTCATTATTGAGAATATATTCAGTAACGATATTTACTTGACTCTGTCGACGGAGAGCGGAGAGGAGTGGAGTGGAAATATCCCCAGTGAATCGGTGACTACTTGGGTCCTTCCGGCCTTGTCTTGA\n" +
  "\n" +
  ">XM_001399115.1 Aspergillus niger CBS 513.88 glycosyl hydrolase, mRNA\n" +
  "ATGTACATCAACCCCATCCTACCGGGATTCAATCCCGACCCCTCTATTATCCGCGTCGGGAATGACTTCTTCCTCGTCACCTCCTCGTTCGAATACTTCCCCGGTGCCCCCATCTACCACAGCACAGACCTTATCCAGTGGAATCTGATCGGCCATGCCCTCACCCGTCCCAGCCAACTCCAGATTCAAACCCCCGAGCCTGGTGGTGGCGTGTGGGCCACCACTCTGCGGTATCACGATGGCGTATTCTACATCCTCGCCGCAAGCTTCCAGCGTTACCGGCCACAGGAAGATGACCGCGTCTGGCCGCGTGGTTTCTATGTGAAGACCAGAAATATATGGGACTCGAGTTCGTGGTCGGACCCGGTGTATTTTGACCAAGTTGGCTTTGATCAAGATCTATTTTGGGACGACGATGGCACCGTCTATCTCTCAAGCACGTATCGCAAGCTTCACCGAACTCCCGGTGTGAACCTCAAGGATTTCGCCGTTCACATTGTCACTGTCGACCTGGAGACAGGTGCCTCCACTTCGGAGCCCAAGTTAATCAGAGAGTCCACCTCCGGTGTCGCGGAGGGGTCGCATATTTTCAAACGCGGCAAATACTACTATCTTTTCACCGCGGAGGGCGGCACGGAGAGCGGTCATTGCGAGTGGGTCAGTCGCAGTACTACTGGCCCGTTCGGGCCATGGGAGATTGGGCCCAATAATCCTCTCTGGAGGAATGGAGTGGATGATGAAGTTCAGAACACTGGGCATGCAGACCTGGTGGAAGATGCGCAGGGGCAGTGGTGGGCTGTTTTGTTAGGTGTGCGTCCTGTCCGGAAGGATGGTCAGTGGGAGGAATCGGTTTTTGGTCGCGAAACATTTCTAGCTCCTGTGAACTGGGAGAACGACTGGCCCATTGTCAACGGTGGCCAAAAGATCCCCCTCCACTCTGAGAGCCCCCACCTCTATGAGCTCTCAGTTCCAGTCACCTGGCGAGATGACTTTTCGGACAGTGAGCTCCAGCTAGGATGGTACCGCAAGAATACTCCAGTTGTGCGTGACTACTCTCTCACCGAGCGACCAGGCCACCTTCGCCTCTACGGTGGTCCATATAATCTGTCCGTCCCGGCGTGTCCAACTCTATTTCTTCGTAAACAGACCCATCGGTTCTGTACATGGGAGACACTGCTGTCTTTCCATCCGAGGAACCAGGCATGCGAGGCTGGTGCAGTCGTCTGGTGGACGTACTTCACCTACAGCAGCATCGGGATTTGTCGAGCGAACGATGGGCAGCGTGTTGTTCGGCTACGTTCTCCGGGAGGTGAGATAGTCGACTTCGGCCTACAGAATTCGCAGTCGGATGTCCGTTTGGTGATCGAGTGTGGGGATCGCTACCGGTTCGGATTTCAGGAGATCAGAGAGGATGAGTCGAATGAAGAGCCGATACGGTGGATCGGCGAGGCGGAGAATCGCAGTATGACAGCAGCACCGCCGGTAGGAGTGGCTTTTACGGGGATGATGGTGGGATTGTACGCGTTTGGAGAGCGGCAACGGTGTCTAGATCCAGCTGACTTTCATTACGCGTTATGGAGATAG\n";

export { aaExample, ntExample };
