<template>
  <v-row no-gutters>
    <v-col class="px-4" cols="12" sm="12" md="5" lg="3" xl="3">
      <v-select
        :items="search_fields"
        label="Search Option"
        v-model="search_field"
        item-text="label"
        item-value="field"
        @change="fieldChanged()"
        return-object
      ></v-select>
    </v-col>
    <v-col
      v-if="search_field.type === 'text'"
      class="px-4"
      cols="12"
      sm="12"
      md="6"
      lg="8"
      xl="8"
    >
      <v-text-field
        v-model="search_value"
        label="Search"
        clearable
        prepend-icon="mdi-magnify"
        @keyup.enter="search"
        @keyup.esc="clear"
        @change="searchChanged"
      ></v-text-field>
    </v-col>
    <v-col
      v-if="search_field.type === 'select'"
      class="px-4"
      cols="12"
      sm="12"
      md="6"
      lg="8"
      xl="8"
    >
      <v-autocomplete
        v-model="search_value"
        :items="search_options"
        label="Search"
        clearable
        prepend-icon="mdi-magnify"
        :loading="is_loading"
        multiple
        :disabled="is_loading"
        @change="searchChanged"
        @input="search_input = ''"
        :search-input.sync="search_input"
        no-data-text="No Options Available"
        item-value="field"
        :item-text="(item) => item.field + ' (' + item.count + ')'"
      ></v-autocomplete>
    </v-col>
    <v-col
      v-if="search_field.type === 'slider'"
      class="px-4"
      cols="12"
      sm="12"
      md="6"
      lg="8"
      xl="8"
    >
      <v-range-slider
        class="pt-3"
        thumb-label
        v-model="search_value"
        :min="slider_min"
        :max="slider_max"
        :step="slider_step"
        @change="searchChanged"
        :loading="is_loading"
        :disabled="is_loading"
      >
        <template v-slot:prepend>
          <v-text-field
            :value="search_value[0]"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 60px"
            @change="$set(search_value, 0, $event)"
            @keyup.enter="searchChanged"
            :disabled="is_loading"
          ></v-text-field>
        </template>
        <template v-slot:append>
          <v-text-field
            :value="search_value[1]"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 60px"
            @change="$set(search_value, 1, $event)"
            @keyup.enter="searchChanged"
            :disabled="is_loading"
          ></v-text-field>
        </template>
      </v-range-slider>
    </v-col>
    <v-col class="px-4" cols="1" sm="1" md="1" lg="1" xl="1">
      <v-btn v-if="is_simple" fab dark color="primary" @click="search">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn v-if="!is_simple" outlined fab @click="remove">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </v-col>
    <error-dialog :error="error" @clear_error="clearError"></error-dialog>
  </v-row>
</template>

<script>
const util = require("../../util/util.js");
import ErrorDialog from "../ErrorDialog.vue";
const axios = require("axios").default;

export default {
  name: "SearchItem",
  components: {
    ErrorDialog,
  },
  props: {
    id: Number, // The id of the search item
    is_simple: Boolean, // is not part of a set
    update_search: Function, // function to call when search needs to be updated
    field: String, // the field for the query
    value: Array, // the current value
    query: Array, // the outer larger query
  },
  data() {
    return {
      slider_min: -1,
      slider_max: -1,
      slider_step: 10,
      search_field: util.getSearchField(this.field), // return the type of search field, based on the value
      search_value: this.value,
      search_input: null, // current input, used in the autocomplete
      is_loading: false, // is loading
      search_options: [], // available options
      search_fields: util.search_fields, // available search fields
      error: null,
    };
  },
  mounted() {
    if (this.value.length > 0) {
      if (this.search_field.type === "slider") {
        this.getSliderOptions();
      } else if (this.search_field.type === "select") {
        this.getOptions();
      }
    }
  },
  watch: {
    field: function (new_field) {
      // watches for a change in field and updates the field
      this.search_field = util.getSearchField(new_field);
    },
    value: function (new_value) {
      // watches changes in value and resets as needed
      if (new_value.length === 0) {
        if (this.search_field.type === "text") {
          this.search_value = "";
        } else if (this.search_field.type === "slider") {
          this.search_value = [-1, -1];
          this.getSliderOptions();
        } else if (this.search_field.type === "select") {
          this.search_value = [];
          this.getOptions();
        }
      }
    },
  },
  methods: {
    // removes the error if any
    clearError() {
      this.error = null;
    },
    // gets slider options from the api
    async getSliderOptions() {
      this.is_loading = true;

      let q = [];

      // main query
      q.push("field=" + this.search_field.select_field);

      // other parts of the query
      Object.keys(this.query).forEach((key) => {
        let item = this.query[key];
        //if the query is upstream, include it
        if (item.id < this.id) {
          if (Array.isArray(item.value)) {
            q.push(item.field + "=" + item.value.join(","));
          } else {
            q.push(item.field + "=" + item.value);
          }
        }
      });

      // create the query string
      let queryParms = q.join("&");

      // here we get data from the server
      axios
        .get(process.env.VUE_APP_SERVER + "/v1/qry/length?" + queryParms)
        .then((response) => {
          if (response.data !== null) {
            this.slider_min = response.data.min;
            this.slider_max = response.data.max;
            this.is_loading = false;
          } else {
            this.slider_min = -1;
            this.slider_max = -1;
            this.is_loading = false;
          }

          // update positions
          if (this.value.length === 0) {
            this.search_value = [this.slider_min, this.slider_max];
          } else {
            this.$set(this.search_value, 0, this.value[0]);
            this.$set(this.search_value, 1, this.value[1]);
          }
        })
        .catch((error) => {
          this.is_loading = false;
          if (error.response !== null) {
            this.error = {
              error: error.response.data.message,
              status: error.response.status,
            };
          } else {
            this.error = {
              error: "Error building select",
              status: 500,
            };
          }
        });
    },
    // lets other components know that the search needs to be updated
    searchChanged() {
      // always passing arrays
      if (!Array.isArray(this.search_value)) {
        this.search_value = [this.search_value];
      }

      this.$emit(
        "update_search",
        this.id,
        this.search_field.field,
        this.search_value
      );
    },
    // gets select options from the api
    async getOptions() {
      this.is_loading = true;

      this.search_options = [];
      let q = [];

      // main query
      q.push("field=" + this.search_field.select_field);

      // other parts of the query
      Object.keys(this.query).forEach((key) => {
        let item = this.query[key];
        //if the query is upstream, include it
        if (item.id < this.id) {
          if (Array.isArray(item.value)) {
            q.push(item.field + "=" + item.value.join(","));
          } else {
            q.push(item.field + "=" + item.value);
          }
        }
      });

      // create the query string
      let queryParams = q.join("&");

      // here we get data from the server
      axios
        .get(process.env.VUE_APP_SERVER + "/v1/qry/options?" + queryParams)
        .then((response) => {
          if (response.data !== null) {
            this.search_options = response.data.options || [];
            this.is_loading = false;
          } else {
            this.search_options = [];
            this.is_loading = false;
          }
        })
        .catch((error) => {
          this.is_loading = false;
          if (error.response !== null) {
            this.error = {
              error: error.response.data.message,
              status: error.response.status,
            };
          } else {
            this.error = {
              error: "Error building select",
              status: 500,
            };
          }
        });
    },
    // search field has changed
    fieldChanged() {
      this.search_value = [];
      this.searchChanged();

      if (this.is_simple) {
        if (this.search_field.type === "select") {
          this.getOptions();
        } else if (this.search_field.type === "slider") {
          this.getSliderOptions();
        }
      }
    },
    // tell the set to remove this search
    remove() {
      this.$emit("remove", this.id);
    },
    // for the simple case where we are  searching on the main page
    search() {
      // stupidity check
      if (this.search_value.length === 0 || this.is_simple === false) {
        return;
      }
      let q = {};

      // always want to treat it as an array
      if (!Array.isArray(this.search_value)) {
        this.search_value = [this.search_value];
      }

      let field = this.search_field.field;
      q[field] = this.search_value.join(",");
      this.$router.push({
        name: "browse",
        query: q,
      });
    },
    // clear the search
    clear() {
      this.search_value = null;
    },
  },
};
</script>

<style></style>
