<template>
  <v-app
    id="main"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <AppBar @toggleNavigationBar="drawer = !drawer" />
    <NavDrawer :toggle="drawer" @drawerIsClosed="drawer = false" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import NavDrawer from "./components/NavDrawer.vue";
export default {
  name: "App",
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  components: {
    AppBar,
    NavDrawer,
  },
};
</script>
