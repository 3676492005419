<template>
  <v-card>
    <v-col>
      <p>
        CUPP is a new approach for sequence analysis employing conserved peptide
        patterns for determination of similarities between proteins.
      </p>
      <p>
        This website is free and open to all users and there is no login
        requirement.
      </p>
      <p>
        The CUPP clustering and CUPP prediction programs can be downloaded
        <router-link to="/downloads">here</router-link>, along with a CUPP
        library of CAZy families:
      </p>
      <p>If you have any questions, please contact cupp@bio.dtu.dk</p>
      <p>
        <strong>When using the CUPP programs or results, please cite:</strong>
      </p>
      <p>
        Barrett, K., Hunt, C. J., Lange, L., & Meyer, A. S. (2020). Conserved
        unique peptide patterns (CUPP) online platform: peptide-based functional
        annotation of carbohydrate active enzymes.
        <i>Nucleic Acids Research</i>, 48(W1), W110–W115.
        <a href="https://doi.org/10.1093/nar/gkaa375"
          >https://doi.org/10.1093/nar/gkaa375</a
        >
      </p>
      <p>
        Barrett, K., & Lange, L. (2019). Peptide-based functional annotation of
        carbohydrate-active enzymes by conserved unique peptide patterns (CUPP).
        <i>Biotechnology for Biofuels</i>, 12(1).
        <a href="https://doi.org/10.1186/s13068-019-1436-5"
          >https://doi.org/10.1186/s13068-019-1436-5</a
        >
      </p>
      <p>If you have any questions, please contact cupp@bio.dtu.dk</p>
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "SubmitInfoCard",
};
</script>

<style></style>
