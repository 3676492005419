import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import DownloadsView from "../views/DownloadsView";
import HelpView from "../views/HelpView";
import SubmitView from "../views/SubmitView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/downloads",
    name: "downloads",
    component: DownloadsView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/submit",
    name: "submit",
    component: SubmitView,
  },
  {
    path: "/status/:id",
    name: "status",
    component: () =>
      import(/* webpackChunkName: "status" */ "../views/JobStatus.vue"),
  },
  {
    path: "/results/:id",
    name: "results",
    component: () =>
      import(/* webpackChunkName: "results" */ "../views/JobResults.vue"),
  },
  {
    path: "/browse",
    name: "browse",
    component: () =>
      import(/* webpackChunkName: "browse" */ "../views/BrowseView.vue"),
  },
  {
    path: "/query",
    name: "query",
    component: () =>
      import(/* webpackChunkName: "query" */ "../views/QueryView.vue"),
  },
  {
    path: "/browse/cazy",
    name: "browse_cazy",
    component: () =>
      import(/* webpackChunkName: "browse_cazy" */ "../views/BrowseCazy.vue"),
  },
  {
    path: "/browse/cupp",
    name: "browse_cupp",
    component: () =>
      import(/* webpackChunkName: "browse_cupp" */ "../views/BrowseCupp.vue"),
  },
  {
    path: "/browse/ec",
    name: "browse_ec",
    component: () =>
      import(/* webpackChunkName: "browse_ec" */ "../views/BrowseEc.vue"),
  },
  {
    path: "/browse/taxonomy",
    name: "browse_tax",
    component: () =>
      import(/* webpackChunkName: "browse_tax" */ "../views/BrowseTax.vue"),
  },
  {
    path: "/browse/strain",
    name: "browse_stn",
    component: () =>
      import(/* webpackChunkName: "browse_stn" */ "../views/BrowseStn.vue"),
  },
  {
    path: "/browse/genome",
    name: "browse_gen",
    component: () =>
      import(/* webpackChunkName: "browse_gen" */ "../views/BrowseGen.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
