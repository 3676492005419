<template>
  <div class="a">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <span class="headline">Quick Query</span>
            </v-card-title>
            <search-item
              :id="0"
              :is_simple="true"
              :query="[]"
              field="search"
              :value="[]"
              :is_top="true"
            />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <span class="headline"> WELCOME TO THE CUPP PLATFORM </span>
            </v-card-title>
            <v-card-text>
              <p>
                Conserved Unique Peptide Patterns (CUPP) is a new approach for
                sequence analysis employing conserved peptide patterns for
                determination of similarities between proteins.
              </p>
              <p>
                CUPP performs unsupervised clustering of proteins for formation
                of protein groups and can be used for fast annotation of enzyme
                protein family, subfamily and EC function of carbohydrate-active
                enzymes.
              </p>
              <p>
                This website is free and open to all users and there is no login
                requirement.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-col>
              <v-row align="center" justify="space-around">
                <v-card-title>
                  <span class="headline">
                    Select an option below to get started:
                  </span>
                </v-card-title>
              </v-row>
              <v-row align="center" justify="space-around" class="pb-6">
                <router-link to="/submit">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 16 : 4"
                      ripple
                      class="rounded-card"
                    >
                      <v-img
                        class="my-2"
                        width="300px"
                        src="@/assets/SUBMIT_CLICK.png"
                      ></v-img>
                    </v-card>
                  </v-hover>
                </router-link>
                <router-link to="/browse/cazy">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 16 : 4"
                      ripple
                      class="rounded-card"
                    >
                      <v-img
                        class="my-2"
                        width="300px"
                        src="@/assets/BROWSE_CLICK.png"
                      ></v-img>
                    </v-card>
                  </v-hover>
                </router-link>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-col>
              <v-row align="center" justify="space-around">
                <v-card-title>
                  <span class="headline">
                    Or use one of the following links
                  </span>
                </v-card-title>
              </v-row>
              <v-row align="center" justify="space-around" class="pb-6">
                <v-btn color="primary" to="browse_tax"> Browse Taxonomy </v-btn>
                <v-btn color="primary" to="browse_cazy"> Browse CAZy </v-btn>
                <v-btn color="primary" to="query"> Advanced Query </v-btn>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <p>
                <strong
                  >When using the CUPP programs or results, please cite:</strong
                >
              </p>
              <p>
                Barrett, K., Hunt, C. J., Lange, L., & Meyer, A. S. (2020).
                Conserved unique peptide patterns (CUPP) online platform:
                peptide-based functional annotation of carbohydrate active
                enzymes.
                <i>Nucleic Acids Research</i>, 48(W1), W110–W115.
                <a href="https://doi.org/10.1093/nar/gkaa375"
                  >https://doi.org/10.1093/nar/gkaa375</a
                >
              </p>
              <p>
                Barrett, K., & Lange, L. (2019). Peptide-based functional
                annotation of carbohydrate-active enzymes by conserved unique
                peptide patterns (CUPP).
                <i>Biotechnology for Biofuels</i>, 12(1).
                <a href="https://doi.org/10.1186/s13068-019-1436-5"
                  >https://doi.org/10.1186/s13068-019-1436-5</a
                >
              </p>
              <p>If you have any questions, please contact cupp@bio.dtu.dk</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SearchItem from "../components/search/SearchItem.vue";
const util = require("../util/util.js");
export default {
  name: "cupp-home",
  components: {
    SearchItem,
  },
  data() {
    return {
      util: util,
    };
  },
};
</script>

<style scoped>
.a >>> .rounded-card {
  border-radius: 40px;
}
</style>
