<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="headline">Help</span>
          </v-card-title>
          <v-card-text>
            <p>
              <strong>Requirements for input:</strong>
            </p>
            <p>
              This is the help page for the CUPP web server. The submission page
              allows you to specify a path to a FASTA file or paste in protein
              sequences in FASTA format (the paste of sequences is currently
              limited to 100 proteins). In case the file extension is .gz, the
              file is expected to be gz compressed and treated accordingly.
              Proteins sequences longer than 50000 amino acids are considered
              erroneous thus will be ignored during annotation. Example proteins
              are provided to enable users to confirm data formatting
              requirements. Additionally, a FASTA file is provided for download,
              with the annotation of the proteins stated in the header for the
              respective protein. See the content of a valid FASTA file below:
            </p>
            <p>
              >EAA29705.1 hypothetical protein [Neurospora crassa]
              <br />MVPSLQTVGHILVTALAIIPFAFADLTTTIDPSSNWGTWEGWGTSLAWWAATFGTRDDLADIFFTTQTTNLNGVSLPGLGFNIARYNAGASSWNSINGTS
              <br />MVVSPKMIPSRQIEGFWIDWASTSPSSASWNWSADSAQRAMLSKARSRGANILELFSNSPMWWMCNNHNPSGSDDGSSDNLQSWNYQSHAVYMATVAKYF
              <br />ADHWSTTFTSVDPFNEPSANWWNGKTGTQEGCHFDVSTQATVLNYLRAELDSRGLSSTVISASDESYYDQAAKTLQNLGSTALGKIARVNVHGYQYGSGR
              <br />RDSVRSLATQYSKKLWQSEYGEGDATGRDLASNLLLDLRWLQPTAWVYWQALDGGGWGLVDADNDKKTIGQPTQKYFVLAQFARHVKPGMRILDGGSDYA
              <br />TAAYDAATKKLVIVAVNWGSAQYLNFDLSRFKTAGVNGAVVKRWATQIGASTGKRYVAASDTYIRGTKFWSYFEKEMVQTFEVEGVVL
            </p>
            <p></p>
            <p>
              <strong>Retrieval of results:</strong>
            </p>
            <p>
              In case an email is provided, a link to the result page can be
              send at the time of submission. The status of the job can be
              checked in the link, indication if the job is queued, running, or
              finished. If desired, an email can be sent when the job has
              finished.
            </p>
            <p />

            <p>
              <strong>Description of output:</strong>
            </p>
            <p>
              The result page is provide an summary of the number of individual
              CAZy families, CAZy subfamilies and EC numbers assigned to each of
              the annotated enzymes. The results are displayed as interactive
              bar charts for each of the types of annotation (CAZy family,
              subfamily CUPP groups and EC numbers). In addition, the results
              can be downloaded as a FASTA file, with the annotation stated in
              the end of the header for the individual entries, as exemplified
              below:
            </p>
            <p>
              >EAA29705.1 hypothetical protein [Neurospora
              crassa]|&|GH30:3.2.1.164|GH30:3.2.1.164|GH30:21.1(27.6,37..433)|GH30_5
              <br />mvpslqtvghilvtalaiipfafadltttidpssnwgtweGWGTSLAWWAATFGtrDDlADIFFTTQTTNlngvsLPgLGFNIArYNAGASSWNsINGTs
              <br />mvvspkmipsrqiegFWIDWAsTSpssaswnwsadsaqRamLSKARsRGaniLELfsNSPMWWMCNNHNpSGSDDGssDNlqSWNYQSHAvymatvakyf
              <br />adhwsttftsvdpfNEPSANWWngKTgtqEGCHFDVSTQatvlnylRaELDsRGLsSTVisAsDESYYDqaAKtlqnlgstalgkiarvnvHGYQYGSGr
              <br />rdsvrslatqyskklwqSEYgEGdatgrdlasnllldLRwLqPTAwVYWQALDGggWglvdadndkktigqptqkyfvLAqFARHVkPGmrILDGGSDyA
              <br />taaydaatkklvivAVNWGSAqylNFdLsRFKTAGvNgavvkrwatqigastgkryvaasdtyirgtkfwsyfekemvqtfevegvvl
            </p>
            <p>
              The beginning of the annotation indicated by a three letter
              separator “|&|” following by four pipe, “|”, separated elements.
              The two first elements is the CAZy family along with the assigned
              EC number, one including only the most abundant EC numbers whereas
              the second includes all regardless of the abundancy of the
              individual EC numbers. The third element is the assigned CUPP
              group with the score and the domain range in parentheses, as
              “GH30:21.1(27.6,37..433)”. This means that the protein has been
              assigned CUPP group 21.1 of GH30, with a score of 27.6 (where 1 is
              the minimum score for assignment) and a conserved domain ranging
              from position 37 to 433 in the amino acid sequence. The letters of
              the amino acid sequence gets lower cased if they were on
              sufficiently covered by the CUPP group peptides used for
              annotation. At the fourth element of the annotation is stated the
              assigned CAZy subfamily, in this case, GH30 subfamily 5).
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelpView",
};
</script>

<style></style>
